<!--优惠活动-->
<template>
  <div class="activity">
    <div class="mb-200">
      <div class="line">
        <span>注册送卡券活动：</span>
        <el-switch v-model="registObj.activityEnable"> </el-switch>
      </div>
      <div class="tips">用户注册完成后，自动发放卡券</div>
      <div class="list" v-if="registObj.activityEnable">
        <div
          class="item"
          v-for="(m, index) in registObj.cardVoList"
          :key="'m' + index"
        >
          <el-select class="inputLength" v-model="m.cardId">
            <el-option
              v-for="item in cardList"
              :key="item.cardId"
              :label="item.cardName"
              :value="item.cardId"
            >
            </el-option>
          </el-select>
          <el-input
            class="inputLength"
            placeholder="请输入内容"
            v-model="m.cardNum"
            oninput="value=value.replace(/[^1-9]/g,(word,key)=> { if(word==='0'&&key!==0) {return word} return '' })"
          >
            <template slot="append">张</template>
          </el-input>
          <el-button
            type="danger"
            icon="el-icon-delete"
            v-if="index != 0"
            @click="goDelete(1, index)"
          ></el-button>
        </div>
      </div>

      <div>
        <el-button @click="goAdd(1)" v-if="registObj.activityEnable"
          >添加卡券</el-button
        >
        <el-button type="primary" @click="goSave(1)">保存设置</el-button>
      </div>
    </div>
    <div>
      <div class="line">
        <span>拉新送卡券活动：</span>
        <el-switch v-model="pullObj.activityEnable"> </el-switch>
      </div>
      <div class="tips">邀请人位置订单消费完成后被邀请人自动获得此奖励</div>
      <div class="list" v-if="pullObj.activityEnable">
        <div
          class="item"
          v-for="(n, index) in pullObj.cardVoList"
          :key="'n' + index"
        >
          <el-select class="inputLength" v-model="n.cardId">
            <el-option
              v-for="item in cardList"
              :key="item.cardId"
              :label="item.cardName"
              :value="item.cardId"
            >
            </el-option>
          </el-select>
          <el-input
            class="inputLength"
            placeholder="请输入内容"
            v-model="n.cardNum"
            oninput="value=value.replace(/[^1-9]/g,(word,key)=> { if(word==='0'&&key!==0) {return word} return '' })"
          >
            <template slot="append">张</template>
          </el-input>
          <el-button
            type="danger"
            icon="el-icon-delete"
            v-if="index != 0"
            @click="goDelete(2, index)"
          ></el-button>
        </div>
      </div>
      <div>
        <el-button @click="goAdd(2)" v-if="pullObj.activityEnable"
          >添加卡券</el-button
        >
        <el-button type="primary" @click="goSave(2)">保存设置</el-button>
      </div>
    </div>
  </div>
</template>
<script>
import { getTableList, getActivity, goUpdateActivity } from "@/api/card/card";

export default {
  data() {
    return {
      registObj: {},
      pullObj: {},

      cardList: [],
    };
  },
  mounted() {
    this.getTableList(); // 【请求】表格数据
    this.getActivity();
  },
  methods: {
    // 保存优惠活动
    goUpdateActivity(obj) {
      goUpdateActivity(obj).then((res) => {
        if (res.isSuccess == "yes") {
          this.$message.success("操作成功");
        }
      });
    },
    // 查询优惠活动
    getActivity() {
      getActivity().then((res) => {
        this.registObj = res.data
          .filter((item) => item.activityType == 1)
          .shift();
        this.pullObj = res.data
          .filter((item) => item.activityType == 2)
          .shift();
      });
    },
    // 保存
    goSave(type) {
      if (type == 1) {
        if (
          this.registObj.cardVoList[this.registObj.cardVoList.length - 1]
            .cardId &&
          this.registObj.cardVoList[this.registObj.cardVoList.length - 1]
            .cardNum
        ) {
          this.goUpdateActivity(this.registObj);
        } else {
          this.$message.error("请先完成上一张卡券的内容填写");
        }
      } else {
        if (
          this.pullObj.cardVoList[this.pullObj.cardVoList.length - 1].cardId &&
          this.pullObj.cardVoList[this.pullObj.cardVoList.length - 1].cardNum
        ) {
          this.goUpdateActivity(this.pullObj);
        } else {
          this.$message.error("请先完成上一张卡券的内容填写");
        }
      }
    },
    // 添加
    goAdd(type) {
      let obj = {
        cardId: "",
        cardNum: 1,
      };

      if (type == 1) {
        if (this.registObj.cardVoList.length == 0) {
          this.registObj.cardVoList.push(obj);
        } else {
          if (
            this.registObj.cardVoList[this.registObj.cardVoList.length - 1]
              .cardId &&
            this.registObj.cardVoList[this.registObj.cardVoList.length - 1]
              .cardNum
          ) {
            this.registObj.cardVoList.push(obj);
          } else {
            this.$message.error("请先完成上一张卡券的内容填写");
          }
        }
      } else {
        if (this.pullObj.cardVoList.length == 0) {
          this.pullObj.cardVoList.push(obj);
        } else {
          if (
            this.pullObj.cardVoList[this.pullObj.cardVoList.length - 1]
              .cardId &&
            this.pullObj.cardVoList[this.pullObj.cardVoList.length - 1].cardNum
          ) {
            this.pullObj.cardVoList.push(obj);
          } else {
            this.$message.error("请先完成上一张卡券的内容填写");
          }
        }
      }
    },
    // 删除
    goDelete(type, index) {
      if (type == 1) {
        this.registObj.cardVoList.splice(index, 1);
      } else {
        this.pullObj.cardVoList.splice(index, 1);
      }
    },
    // 【请求】表格数据
    getTableList() {
      let data = {
        page: 1,
        size: 0,
      };
      getTableList(data).then((res) => {
        if (res.isSuccess == "yes") {
          this.cardList = res.data.list;
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.activity {
  padding: 0.15rem;
  box-sizing: border-box;
}
.line {
  display: flex;
}
.mb-200 {
  margin-bottom: 200px;
}
.tips {
  color: #999;
  font-size: 12px;
  margin-bottom: 30px;
}
.list {
  margin-bottom: 30px;
  .item {
    display: flex;
    margin-bottom: 20px;
  }
  .inputLength {
    width: 300px;
    margin-right: 30px;
  }
}
</style>